import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { PageProps } from '../types'

const NotFoundPage = ({ data, location }: PageProps): JSX.Element => {
  return (
    <Layout
      location={location}
      title={'404: Not Found'}
      site={data.site}
      pages={data.pages}>
      <SEO title="404: Not Found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author {
          name
          summary
        }
      }
    }
    pages: allFile(
      filter: {
        sourceInstanceName: { eq: "pages" }
        childMarkdownRemark: { id: { glob: "*" } }
      }
    ) {
      nodes {
        childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
